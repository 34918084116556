'use strict';

var ES5Type = require('../5/Type'); // https://www.ecma-international.org/ecma-262/6.0/#sec-tostring


module.exports = function Type(x) {
  if (typeof x === 'symbol') {
    return 'Symbol';
  }

  return ES5Type(x);
};