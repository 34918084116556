import { Invert, Split, SplitRight, Horizontal, FullScreenCode, Appear } from "mdx-deck";
import { CodeSurfer, CodeSurferColumns, Step } from "code-surfer";
import { nightOwl } from "@code-surfer/themes";
import Layout from "../../../../../../components/Layout";
import React from 'react';
export default {
  Invert: Invert,
  Split: Split,
  SplitRight: SplitRight,
  Horizontal: Horizontal,
  FullScreenCode: FullScreenCode,
  Appear: Appear,
  CodeSurfer: CodeSurfer,
  CodeSurferColumns: CodeSurferColumns,
  Step: Step,
  nightOwl: nightOwl,
  Layout: Layout,
  React: React
};