'use strict';

var GetIntrinsic = require('../GetIntrinsic');

var $TypeError = GetIntrinsic('%TypeError%');

var inspect = require('object-inspect');

var IsCallable = require('./IsCallable'); // https://www.ecma-international.org/ecma-262/6.0/#sec-call


module.exports = function Call(F, V) {
  var args = arguments.length > 2 ? arguments[2] : [];

  if (!IsCallable(F)) {
    throw new $TypeError(inspect(F) + ' is not a function');
  }

  return F.apply(V, args);
};