'use strict';

require("core-js/modules/es6.regexp.constructor");

var GetIntrinsic = require('../GetIntrinsic');

var $TypeError = GetIntrinsic('%TypeError%');
var $Number = GetIntrinsic('%Number%');
var $RegExp = GetIntrinsic('%RegExp%');
var $parseInteger = GetIntrinsic('%parseInt%');

var callBound = require('../helpers/callBound');

var regexTester = require('../helpers/regexTester');

var isPrimitive = require('../helpers/isPrimitive');

var $strSlice = callBound('String.prototype.slice');
var isBinary = regexTester(/^0b[01]+$/i);
var isOctal = regexTester(/^0o[0-7]+$/i);
var isInvalidHexLiteral = regexTester(/^[-+]0x[0-9a-f]+$/i);
var nonWS = ["\x85", "\u200B", "\uFFFE"].join('');
var nonWSregex = new $RegExp('[' + nonWS + ']', 'g');
var hasNonWS = regexTester(nonWSregex); // whitespace from: https://es5.github.io/#x15.5.4.20
// implementation from https://github.com/es-shims/es5-shim/blob/v3.4.0/es5-shim.js#L1304-L1324

var ws = ["\t\n\x0B\f\r \xA0\u1680\u180E\u2000\u2001\u2002\u2003", "\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u3000\u2028", "\u2029\uFEFF"].join('');
var trimRegex = new RegExp('(^[' + ws + ']+)|([' + ws + ']+$)', 'g');
var $replace = callBound('String.prototype.replace');

var $trim = function $trim(value) {
  return $replace(value, trimRegex, '');
};

var ToPrimitive = require('./ToPrimitive'); // https://www.ecma-international.org/ecma-262/6.0/#sec-tonumber


module.exports = function ToNumber(argument) {
  var value = isPrimitive(argument) ? argument : ToPrimitive(argument, $Number);

  if (typeof value === 'symbol') {
    throw new $TypeError('Cannot convert a Symbol value to a number');
  }

  if (typeof value === 'string') {
    if (isBinary(value)) {
      return ToNumber($parseInteger($strSlice(value, 2), 2));
    } else if (isOctal(value)) {
      return ToNumber($parseInteger($strSlice(value, 2), 8));
    } else if (hasNonWS(value) || isInvalidHexLiteral(value)) {
      return NaN;
    } else {
      var trimmed = $trim(value);

      if (trimmed !== value) {
        return ToNumber(trimmed);
      }
    }
  }

  return $Number(value);
};